<template>
  <div v-if="loading">
    Loading...
  </div>
  <div class="tables" v-else>
    <div class="table">
      <!-- Custom Search Bar -->
      <div class="tt-search vgt-global-search vgt-clearfix">
        <div class="vgt-global-search__input vgt-pull-left">
          <span class="input__icon">
            <div class="magnifying-glass"></div>
          </span>
          <input
            type="text"
            v-model="searchProduct"
            class="vgt-input vgt-pull-left"
            placeholder="Search product records"
          >
        </div>
        <div class="vgt-global-search__actions vgt-pull-right"></div>
      </div>
      <vue-good-table
      title="Products"
      :columns="productColumns"
      :rows="getProductsByPackage(pkg.Id)"
      max-height="700px"
      :fixed-header="true"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        rowsPerPageLabel: 'Products per Page'
      }"
      :lineNumbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchProduct
      }"
      :defaultSortBy="{ field: 'Name' }"
    />
    </div>
    <div class="table">
      <!-- Custom Search Bar -->
      <div class="tt-search vgt-global-search vgt-clearfix">
        <div class="vgt-global-search__input vgt-pull-left">
          <span class="input__icon">
            <div class="magnifying-glass"></div>
          </span>
          <input
            type="text"
            v-model="searchStay"
            class="vgt-input vgt-pull-left"
            placeholder="Search stay records"
          >
        </div>
        <div class="vgt-global-search__actions vgt-pull-right"></div>
      </div>
      <vue-good-table
      title="Stays"
      :columns="stayColumns"
      :rows="getStaysByPackage(pkg.Id)"
      max-height="700px"
      :fixed-header="true"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        rowsPerPageLabel: 'Stays per Page'
      }"
      :lineNumbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchStay
      }"
      :defaultSortBy="{ field: 'Name' }"
    />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* global jsforce */
export default {
  props: [
    'pkg'
  ],

  data() {
    return {
      loading: true,
      productColumns: [
        {
          label: 'Name',
          field: 'Name',
          html: false,
        },
        {
          label: 'Inventory',
          field: 'inventory',
          html: false,
          sortFn: this.inventorySort,
        },
        {
          label: 'Price',
          field: 'Price__c',
          type: 'decimal',
          html: false,
        },
      ],
      stayColumns: [
        {
          label: 'Name',
          field: 'Name',
          html: false,
        },
        {
          label: 'Price',
          field: 'Price__c',
          type: 'decimal',
          html: false,
        },
        {
          label: 'Inventory',
          field: 'Current_Inventory__c',
          html: false,
          type: 'number',
        },
        {
          label: 'Available',
          field: 'Available__c',
          html: false,
          type: 'boolean',
        },
        {
          label: 'Room Type',
          field: 'Room_Type__c',
          html: false,
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['getProductsByPackage', 'getStaysByPackage']),
    searchProduct: {
      get() {
        return this.$store.state.searchProduct
      },
      set(val) {
        this.setProductSearch(val)
      }
    },
    searchStay: {
      get() {
        return this.$store.state.searchStay
      },
      set(val) {
        this.setStaySearch(val)
      }
    },
  },

  async created() {
    const signedRequest = document.getElementById('sf-canvas-signed-request').content
    const connection = new jsforce.Connection({ signedRequest })
    const query = await connection.query(`Select Id, Name, Current_Inventory__c, Available__c, Price__c, Product_Type__c From Product2 Where isActive = true AND Id IN (Select Child_product__c From Products_Bundle__c Where Parent_Product__c = '${this.pkg.Id}')`)
    const products = query.records.filter(record => record.Product_Type__c !== 'Hotel')
    const hotels = query.records.filter(record => record.Product_Type__c === 'Hotel')

    let stays = []
    for (const hotel of hotels) {
      if (hotel.Product_Type__c !== 'Hotel') continue
      const queryStays = await connection.query(`Select Id, Name, Current_Inventory__c, Available__c, Price__c, Room_Type__c From Product2 Where isActive = true AND Id IN (Select Child_product__c From Products_Bundle__c Where Parent_Product__c = '${hotel.Id}')`)
      stays = stays.concat(queryStays.records)
    }

    products.map(product => {
      product.inventory = product.Available__c ? 'Available' : product.Current_Inventory__c
    })
    if (!this.getProductsByPackage(this.pkg.Id)) {
      this.setProducts({packageId: this.pkg.Id, products: products})
    }
    if (!this.getStaysByPackage(this.pkg.Id)) {
      this.setStays({packageId: this.pkg.Id, stays})
    }
    this.loading = false
  },

  methods: {
    ...mapMutations(['setProducts', 'setStays', 'setProductSearch', 'setStaySearch']),
    inventorySort(x, y, col, rowX, rowY) {
      if (x === 'Available' && y === 'Available') {
        return 0
      }
      if (x === 'Available') {
        return 1
      }
      if (y === 'Available') {
        return -1
      }
      const xInt = parseInt(x)
      const yInt = parseInt(y)
      return (xInt < yInt ? -1 : (xInt > yInt ? 1 : 0))
    }

  },
}

</script>

<style scoped>
  .tables {
    display: flex;
    justify-content: space-evenly;
  }
  .table {
    width: 45%;
  }
</style>
