<template>
  <div v-if="loading">
    Loading...
  </div>
  <div v-else-if="selected">
    <Event :event="selected" />
  </div>
  <div v-else>
    <!-- Custom Search Bar -->
    <div class="tt-search vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon">
          <div class="magnifying-glass"></div>
        </span>
        <input
          type="text"
          v-model="searchTerm"
          class="vgt-input vgt-pull-left"
          placeholder="Search event records"
        >
      </div>
      <div class="vgt-global-search__actions vgt-pull-right"></div>
    </div>
    <vue-good-table
      title="Events"
      :columns="columns"
      :rows="events"
      max-height="700px"
      :fixed-header="true"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        rowsPerPageLabel: 'Events per Page'
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :sort-options="{ field: 'Name' }"
      v-on:row-click="onClick"
    />
  </div>
</template>

<script>
/* global jsforce */
import { mapMutations, mapState } from 'vuex'
import Event from './Event.vue'

export default {
  components: {
    Event
  },

  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Name',
          field: 'Name',
          html: false,
        }
      ]
    }
  },

  computed: {
    ...mapState({
      events: state => state.events,
      selected: state => state.selectedEvent
    }),
    searchTerm: {
      get() {
        return this.$store.state.searchEvent
      },
      set(val) {
        this.setEventSearch(val)
      }
    },
  },

  async created() {
    const signedRequest = document.getElementById('sf-canvas-signed-request').content
    const connection = new jsforce.Connection({ signedRequest })
    const query = await connection.query("SELECT ID, Name FROM Pricebook2 WHERE isActive = true")
    this.setEvents(query.records)
    this.loading = false
  },

  methods: {
    ...mapMutations(['setEvents', 'setSelectedEvent', 'setEventSearch']),
    onClick(params) {
      this.setSelectedEvent(params.row)
    },
  }
}
</script>

<style scoped>

</style>
