<template>
  <div id="app">
    <Events/>
  </div>
</template>

<script>
import Events from './Events.vue'

export default {
  data: function () {
    return {
    }
  },
  components: {
    Events
  },
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
