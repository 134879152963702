/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> and
// <%= stylesheet_pack_tag 'hello_vue' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.


import { createApp }from 'vue'
// import Vuex from 'vuex'
// Vue.use(Vuex)
import VueGoodTablePlugin from 'vue-good-table-next'
import 'vue-good-table-next/dist/vue-good-table-next.css'

import App from './components/app.vue'
import { store } from './store'

// Vue.use(VueGoodTable);

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(document.createElement('hello'))
  const app = createApp(App)
  app.use(store)
  app.use(VueGoodTablePlugin)
  app.mount('hello')
})

Sfdc.canvas(function() {
  const { client } = document.getElementById('sf-canvas-signed-request').content
  Sfdc.canvas.client.autogrow(client)
})
