<template>
  <div>
    Event: {{ event.Name }}
    <button @click="removeSelection()">Back</button>
    <Packages :event="event"/>
  </div>
</template>

<script>
/* global jsforce */
import Packages from './Packages.vue'
import { mapMutations } from 'vuex'

export default {
  props: [
    'event'
  ],

  components: {
    Packages
  },

  methods: {
    ...mapMutations([
      'setSelectedEvent',
      'setSelectedPackage',
      'setPackageSearch',
      'setProductSearch',
      'setStaySearch'
    ]),
    removeSelection() {
      this.setSelectedEvent(null)
      this.setSelectedPackage(null)
      this.setPackageSearch('')
      this.setProductSearch('')
      this.setStaySearch('')
    }
  }
}
</script>

<style scoped>

</style>
