<template>
  <div>
    Package: {{ pkg.Name }}
    <button @click="removeSelection()">Back</button>
    <Products :pkg="pkg"/>
  </div>
</template>

<script>
/* global jsforce */
import Products from './Products.vue'
import { mapMutations } from 'vuex'

export default {
  props: [
    'pkg'
  ],

  components: {
    Products
  },

  methods: {
    ...mapMutations([
      'setSelectedPackage',
      'setProductSearch',
      'setStaySearch'
    ]),
    removeSelection() {
      this.setSelectedPackage(null)
      this.setProductSearch('')
      this.setStaySearch('')
    }
  }
}
</script>

<style scoped>

</style>
