// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
/* global jsforce */
console.log('loading application.js in /javascript')
import './hello_vue'
// import 'app.scss'

// import { Connection } from 'jsforce'

const signedRequest = document.getElementById('sf-canvas-signed-request').content

const conn = new jsforce.Connection({ signedRequest })

conn.query('SELECT Id, Name FROM QE360_Event__c', function(err, res) {
  if (err) { return console.log(err); }
  console.log(res);
})