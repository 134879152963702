<template>
  <div v-if="loading">
    Loading...
  </div>
  <div v-else-if="selected">
    <Package :pkg="selected" />
  </div>
  <div v-else>
    <!-- Custom Search Bar -->
    <div class="tt-search vgt-global-search vgt-clearfix">
      <div class="vgt-global-search__input vgt-pull-left">
        <span class="input__icon">
          <div class="magnifying-glass"></div>
        </span>
        <input
          type="text"
          v-model="searchTerm"
          class="vgt-input vgt-pull-left"
          placeholder="Search package records"
        >
      </div>
      <div class="vgt-global-search__actions vgt-pull-right"></div>
    </div>
    <vue-good-table
      title="Packages"
      :columns="columns"
      :rows="getPackagesByEvent(event.Id)"
      max-height="700px"
      :fixed-header="true"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 10,
        rowsPerPageLabel: 'Packages per Page'
      }"
      :lineNumbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :sort-options="{ field: 'Name' }"
      v-on:row-click="onClick"
    />
  </div>
</template>

<script>
import 'regenerator-runtime/runtime'
import { httpHeaders } from '../local_fetch_call'
import axios from 'axios'
import Package from './Package.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
/* global jsforce */
export default {
  props: [
    'event'
  ],

  components: {
    Package
  },

  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Name',
          field: 'Name',
          html: false,
        },
        {
          label: 'Price',
          field: 'Price__c',
          type: 'decimal',
          html: false,
        },
        {
          label: 'Package Inventory',
          field: 'Package_Inventory__c',
          html: false,
          sortFn: this.inventorySort,
        },
        {
          label: 'Hotel Inventory',
          field: 'Hotel_Inventory__c',
          type: 'number',
          html: false,
        },
        {
          label: 'Hotel Available',
          field: 'Hotel_Available',
          type: 'boolean',
          html: false,
        },
      ]
    }
  },

  computed: {
    ...mapState({
      packages: state => state.packages,
      selected: state => state.selectedPackage
    }),
    ...mapGetters(['getPackagesByEvent']),
    searchTerm: {
      get() {
        return this.$store.state.searchPackage
      },
      set(val) {
        this.setPackageSearch(val)
      }
    },
  },

  async created() {
    if (this.getPackagesByEvent(this.event.Id)) this.loading = false
    else await this.queryPackages()
  },

  methods: {
    ...mapMutations(['setPackages', 'setSelectedPackage', 'setPackageSearch']),
    async queryPackages() {
      const signedRequest = document.getElementById('sf-canvas-signed-request').content
      const connection = new jsforce.Connection({ signedRequest })
      const query = await connection.query(`Select Id, Name, Price__c, Package_Inventory__c From Product2 Where Id IN (Select Product2Id From PricebookEntry Where Pricebook2Id = '${this.event.Id}' AND CurrencyIsoCode = 'USD') AND RecordType.name = 'Package' AND isActive = true`)
      let records = query.records

      const recordIds = records.map(record => record.Id)
      const sfQuery = await axios.get(
        `/hotel_inventories?package_ids=${recordIds}`,
        {package_ids: recordIds},
        httpHeaders
      )

      for (const record of records) {
        console.log('RECORD', record)
        record.eventId = this.event.Id
        record.Hotel_Available = false
        const hotels = sfQuery.data[record.Id][0]['hotels']

        let hotelQty = 0
        for (const hotel of hotels) {
          for (const stay of hotel.stays) {
            if (stay.sfAvailable && !record.Hotel_Available) {
              record.Hotel_Available = true
            }
            if (stay.sfAvailable || !stay.active || stay.inventoryOnSite < 0) continue
            hotelQty += stay.inventoryOnSite
          }
        }
        record.Hotel_Inventory__c = hotelQty
      }

      if (!this.getPackagesByEvent(this.event.Id)) {
        this.setPackages({eventId: this.event.Id, packages: records})
      }
      this.loading = false
    },
    onClick(params) {
      this.setSelectedPackage(params.row)
    },
    inventorySort(x, y, col, rowX, rowY) {
      if (x === 'Available' && y === 'Available') {
        return 0
      }
      if (x === 'Available') {
        return 1
      }
      if (y === 'Available') {
        return -1
      }
      const xInt = parseInt(x)
      const yInt = parseInt(y)
      return (xInt < yInt ? -1 : (xInt > yInt ? 1 : 0))
    },
  }
}
</script>

<style>

</style>
