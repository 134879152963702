// import Vue from 'vue'
import {createStore} from 'vuex'
// Vue.use(Vuex)

export const store = createStore({
  state: {
    searchEvent: '',
    searchPackage: '',
    searchProduct: '',
    searchStay: '',
    events: [],
    selectedEvent: null,
    packages: new Map(),
    selectedPackage: null,
    products: new Map(),
    stays: new Map(),
  },
  mutations: {
    setEventSearch(state, term) {
      state.searchEvent = term
    },
    setPackageSearch(state, term) {
      state.searchPackage = term
    },
    setProductSearch(state, term) {
      state.searchProduct = term
    },
    setStaySearch(state, term) {
      state.searchStay = term
    },
    setEvents(state, events) {
      state.events = events
    },
    setSelectedEvent(state, event) {
      state.selectedEvent = event
    },
    setPackages(state, {eventId, packages}) {
      state.packages.set(eventId, packages)
    },
    setSelectedPackage(state, selectedPackage) {
      state.selectedPackage = selectedPackage
    },
    setProducts(state, {packageId, products}) {
      state.products.set(packageId, products)
    },
    setStays(state, {packageId, stays}) {
      state.stays.set(packageId, stays)
    }
  },
  getters: {
    getPackagesByEvent: state => eventId => {
      return state.packages.get(eventId)
    },
    getProductsByPackage: state => packageId => {
      return state.products.get(packageId)
    },
    getStaysByPackage: state => packageId => {
      return state.stays.get(packageId)
    }
  },
  actions: {},
})