// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
p[data-v-34077952] {
  font-size: 2em;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./app/javascript/components/app.vue"],"names":[],"mappings":";AAqBA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["<template>\n  <div id=\"app\">\n    <Events/>\n  </div>\n</template>\n\n<script>\nimport Events from './Events.vue'\n\nexport default {\n  data: function () {\n    return {\n    }\n  },\n  components: {\n    Events\n  },\n}\n</script>\n\n<style scoped>\np {\n  font-size: 2em;\n  text-align: center;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
